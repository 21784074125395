<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Notifications
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-menu ref="dayMenu" v-model="showDayMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="selectedDay" label="Day" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="selectedDay" no-title scrollable @change="$refs.dayMenu.save(selectedDay)"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedAdNetwork" :items="adNetworks" label="Ad network" clearable prepend-icon="stream">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedVerticals" :items="activeVerticals" item-text="name" item-value="name" multiple label="Verticals" 
              clearable prepend-icon="construction">
            </v-autocomplete>
          </v-col>

          <v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedCountries" :items="activeCountries" item-text="iso2" item-value="iso2" multiple label="Countries" 
              clearable prepend-icon="public">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-text-field v-model="accountNameSearch" label="Account name" clearable prepend-icon="text_snippet">
            </v-text-field>
          </v-col>

          <v-col>
          </v-col>

          <v-col>
            <v-btn class="mt-2" color="primary" @click="show">Show</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'NotificationsTable',

  data () {
    return {
      headers: [
        { text: 'Day', value: 'day' },
        { text: 'Ad network', value: 'ad_network' },
        { text: 'Account', value: 'account_name' },
        { text: 'Level', value: 'level' },
        { text: 'Condition', value: 'condition' },
        { text: 'Recipient(s)', value: 'emails_csv' },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      showDayMenu: false,
      selectedDay: null,
      selectedAdNetwork: null,
      selectedVerticals: [],
      selectedCountries: [],
      accountNameSearch: '',
      lsOptions: 'notifications_options',
      lsFilters: 'notifications_filters',
      endpoint: '/a/core/notifications',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    activeCountries () {
      return this.$store.state.common.activeCountries
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals
    },
    adNetworks () {
      return this.$store.state.core.adNetworks
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      this.loading = true
      var myURL = this.endpoint + '?is_sent=true&xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, [], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter selected day
      if (this.selectedDay) {
        myURL += '&day=' + this.selectedDay
      }

      // filter ad network
      if (this.selectedAdNetwork) {
        myURL += '&ad_network=' + this.selectedAdNetwork
      }

      // filter verticals
      if (this.selectedVerticals.length > 0) {
        myURL += '&vertical=~[' + this.selectedVerticals.join('|') + ']~'
      }

      // filter countries
      if (this.selectedCountries.length > 0) {
        myURL += '&country_iso2=~[' + this.selectedCountries.join('|') + ']~'
      }

      // filter account name search
      if (this.accountNameSearch) {
        myURL += '&account_name=~' + this.accountNameSearch + '~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
     let filterObj = {
        'selectedDay': this.selectedDay,
        'selectedAdNetwork': this.selectedAdNetwork,
        'selectedVerticals': this.selectedVerticals,
        'selectedCountries': this.selectedCountries,
        'accountNameSearch': this.accountNameSearch,
      }

      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // default day = today
    this.selectedDay = this.$dateTime.now().toISODate()

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedDay']) { this.selectedDay = filters['selectedDay'] }
      if (filters['selectedAdNetwork']) { this.selectedAdNetwork = filters['selectedAdNetwork'] }
      if (filters['selectedVerticals']) { this.selectedVerticals = filters['selectedVerticals'] }
      if (filters['selectedCountries']) { this.selectedCountries = filters['selectedCountries'] }
      if (filters['accountNameSearch']) { this.accountNameSearch = filters['accountNameSearch'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
